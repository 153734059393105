<app-navbar></app-navbar>
<main class="page-content">
  <div class="text-light fw-bold bg-primary">
    <div class="container py-3 d-flex justify-content-between align-items-center">
      <h5 class="my-4">سياسة الخصوصية</h5>
      <h5 class="my-4">Privacy Policy</h5>
    </div>
  </div>
  <main class="page-content">
    <div class="my-5">
      <div class="container">
        <div class="row">
          <div dir="rtl" class="col-lg-6">
            <p><b>سياسة الخصوصية</b></p>
            <p>
              <b>آخر تحديث: 1 يناير 2025</b>
            </p>
            <p>
              توضح سياسة الخصوصية هذه الخاصة بمكتب التربية العربي لدول الخليج كيفية الوصول إلى المعلومات الشخصية وجمعها
              وتخزينها واستخدامها و/أو مشاركتها عند استخدامك منصة التدريب الخليجي، بما في ذلك:
            </p>
            <ul>
              <li>تنزيل واستخدام تطبيق الهاتف، أو أي تطبيق آخر تابع لنا مرتبط بسياسة الخصوصية هذه.</li>
            </ul>
            <hr />
            <p><b>1. المعلومات التي يتم جمعها؟</b></p>
            <p>يتم طلب المعلومات الشخصية التالية عند استخدام المنصة:</p>
            <ul>
              <li>الاسم الكامل</li>
              <li>تاريخ الميلاد</li>
              <li>جهة العمل والمسمى الوظيفي</li>
              <li>الجنس</li>
              <li>عنوان البريد الإلكتروني</li>
              <li>الدولة</li>
              <li>رقم الهاتف</li>
            </ul>
            <p>بالإضافة إلى ذلك، قد يتطلب التطبيق الأذونات التالية:</p>
            <ul>
              <li>الوصول إلى الكاميرا: لتمكين الميزات التي تتطلب التقاط الصورة للملف الشخصي.</li>
              <li>الوصول إلى مكتبة الصور: للسماح للمستخدمين بتحميل أو اختيار صور الملف الشخصي.</li>
              <li>الإشعارات الفورية: لتقديم التحديثات والتنبيهات ذات الصلة.</li>
            </ul>
            <p>نحن لا نجمع معلومات شخصية حساسة.</p>
            <hr />
            <p>
              <b>2. كيف نعالج معلوماتك؟</b>
            </p>
            <p>نعالج معلوماتك الشخصية للأغراض التالية:</p>
            <ul>
              <li>إنشاء وإدارة حسابات المستخدمين.</li>
              <li>تقديم خدماتنا وتشغيلها وتحسينها.</li>
              <li>الرد على استفسارات المستخدمين وتقديم الدعم.</li>
              <li>إرسال إشعارات تتعلق بنشاط الحساب أو ميزات التطبيق.</li>
            </ul>
            <p>لا يتم مشاركة أي معلوماتك مع أي طرف ثالث.</p>
            <hr />
            <p><b>3. كيف نحافظ على أمان معلوماتك؟</b></p>
            <p>
              ننّفذ إجراءات أمنية مناسبة لحماية معلوماتك الشخصية. ومع ذلك، لا يوجد نظام آمن تمامًا، ولا يمكننا ضمان الحماية
              المطلقة ضد الوصول غير المصرح به.
            </p>
            <hr />
            <p><b>4. ما هي مدة احتفاظنا بمعلوماتك؟</b></p>
            <p>نحتفظ بمعلوماتك الشخصية فقط طالما كان ذلك ضروريًا لتحقيق الأغراض الموضحة في سياسة الخصوصية هذه.</p>
            <hr />
            <p><b>5. ما هي حقوق الخصوصية الخاصة بك؟</b></p>
            <p>لديك الحق في:</p>
            <ul>
              <li>مراجعة معلوماتك الشخصية أو تحديثها أو حذفها.</li>
              <li>سحب الموافقة على معالجة البيانات (عند طلب حذف الحساب).</li>
            </ul>
            <hr />
            <p><b>6. كيف يمكنك الاتصال بنا؟</b></p>
            <p>
              إذا كانت لديك أسئلة، يمكنك الاتصال:
              <br />
              بمكتب التربية العربي لدول الخليج
              <br />
              طريق الملك سعود، حي الوزارات، الرياض، المملكة العربية السعودية
              <br />
              البريد الإلكتروني:
              <a href="mailto: abegs@abegs.org">abegs&#64;abegs.org</a>
              <br />
              هاتف:
              <a href="tel: +966112664470" dir="ltr">+966112664470</a>
            </p>
          </div>
          <div dir="ltr" class="col-lg-6">
            <p><b>PRIVACY POLICY</b></p>
            <p><b>Last updated January 01, 2025</b></p>
            <p>
              This Privacy Notice for
              <b>the Arab Bureau of Education for the Gulf States</b>
              ("we," "us," or "our"), describes how and why we might access, collect, store, use, and/or share ("process")
              your personal information when you use our services ("Services"), including when you:
            </p>
            <ul>
              <li>
                Download and use our mobile application (منصة التدريب الخليجي), or any other application of ours that is
                related to this Privacy Policy.
              </li>
            </ul>
            <hr />
            <p><b>1. WHAT INFORMATION DO WE COLLECT?</b></p>
            <p>We collect the following personal information that you voluntarily provide when using our Services:</p>
            <ul>
              <li>Full Name</li>
              <li>Date of Birth</li>
              <li>Job Title</li>
              <li>Gender</li>
              <li>Email address</li>
              <li>Country</li>
              <li>Phone number</li>
            </ul>
            <p>Additionally, our application may require the following permissions:</p>
            <ul>
              <li>
                <b>Camera Access</b>
                : To enable features that require photo or video capture.
              </li>
              <li>
                <b>Photo Library</b>
                Access: To allow users to upload or select profile images.
              </li>
              <li>
                <b>Push Notifications</b>
                : To provide updates and relevant alerts.
              </li>
            </ul>
            <p>We do not collect sensitive personal information.</p>
            <hr />
            <p><b>2. HOW DO WE PROCESS YOUR INFORMATION?</b></p>
            <p>We process your personal information for the following purposes:</p>
            <ul>
              <li>To create and manage user accounts.</li>
              <li>To provide, operate, and improve our Services.</li>
              <li>To respond to user inquiries and provide support.</li>
              <li>To send notifications related to account activity or application features.</li>
              <li>To ensure the security of our Services.</li>
            </ul>
            <p>
              We do
              <b>not</b>
              share your information with third parties.
            </p>
            <hr />
            <p><b>3. HOW DO WE KEEP YOUR INFORMATION SAFE?</b></p>
            <p>
              We implement appropriate security measures to protect your personal information. However, no system is
              completely secure, and we cannot guarantee absolute protection against unauthorized access.
            </p>
            <p><b>4. HOW LONG DO WE KEEP YOUR INFORMATION?</b></p>
            <p>
              We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy
              Policy.
            </p>
            <p><b>5. WHAT ARE YOUR PRIVACY RIGHTS?</b></p>
            <p>You have the right to:</p>
            <ul>
              <li>Review, update, or delete your personal information.</li>
              <li>Withdraw consent for data processing (where applicable).</li>
            </ul>
            <hr />
            <p><b>6. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b></p>
            <p>
              If you have questions or concerns, you may contact us at:
              <br />
              <b>Arab Bureau of Education for the Gulf States</b>
              <br />
              King Saud Road, Al Wizarat, Riyadh, Saudi Arabia
              <br />
              Email:
              <a href="mailto: abegs@abegs.org">abegs&#64;abegs.org</a>
              <br />
              Phone:
              <a href="tel: +966112664470" dir="ltr">+966112664470</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</main>
<app-footer></app-footer>
