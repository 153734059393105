<app-navbar></app-navbar>
<main class="page-content">
  <!-- <div id="home-carousel" class="carousel slide carousel-fade" data-bs-ride="true">
    <div class="carousel-inner">
      <div *ngFor="let slide of slides" class="carousel-item" [ngClass]="{active: slide.active}">
        <img [src]="slide.imageSrc" class="w-100 h-100 intro-image" [alt]="slide.title" />
        <div class="carousel-caption text-center">
          <h5 class="title fw-bold">{{ slide.title }}</h5>
          <p class="subtitle lead fw-bold mt-4">{{ slide.subtitle }}</p>
          <a routerLink="/events" class="btn btn-primary px-4 py-3 mt-4 fw-bold">تصفح الفعاليات</a>
        </div>
      </div>
    </div>
  </div> -->
  <div id="home-app-cta">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center align-items-lg-start">
          <h6 class="fs-1 fw-bold text-secondary mt-4 mb-3">حمل التطبيق الآن!</h6>
          <p class="lead mb-5 text-center text-lg-start">
            يمكنكم الآن متابعة أحدث الندوات و اللقاءات التي يقدمها مكتب التربية العربي لدول الخليج من خلال تنزيل تطبيق الجوال
          </p>
          <div>
            <a href="https://play.google.com/store/apps/details?id=org.abegs.faalyat&hl=ar">
              <img alt="احصل عليه من Google Play" src="./assets/images/landing/play-store-badge.png" style="height: 75px" />
            </a>
            <a
              href="https://apps.apple.com/us/app/%D9%85%D9%86%D8%B5%D8%A9-%D8%A7%D9%84%D8%AA%D8%AF%D8%B1%D9%8A%D8%A8-%D8%A7%D9%84%D8%AE%D9%84%D9%8A%D8%AC%D9%8A/id6502260488?itsct=apps_box_badge&amp;itscg=30200"
            >
              <img src="./assets/images/landing/app-store-badge.svg" alt="تنزيل من App Store" style="height: 50px" />
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <img
            style="max-width: 100%; background-blend-mode: multiply"
            src="./assets/images/landing/mockup.png"
            alt="التطبيق"
          />
        </div>
      </div>
    </div>
  </div>

  <div id="home-skills" class="py-5 world end">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h6 class="fw-bold text-primary mt-5">تعرف على فعاليات مكتب التربية العربي لدول الخليج</h6>
          <h6 class="fs-1 fw-bold text-secondary mt-4 mb-3">طور مهاراتك وتعلم معنا من أي مكان</h6>
          <p class="lead mb-5">
            يوفر مكتب التربية العربي لدول الخليج برامج تدريبية للمختصين و الباحثين و الباحثات و المهتمين بالمجال التربوي و
            التعليمي في الدول العربية، و يتيح المشاركة في إعداد التدريب و تنفيذه على المنصة وفق معايير و آليات تنفيذ تضمن
            جودة محتوي التصميم و التدريب
          </p>

          <div class="row">
            <div class="col-6">
              <p class="fs-5 fw-bold">
                <fa-icon
                  [icon]="faCheck"
                  style="width: 40px; height: 40px"
                  class="me-3 fs-3 bg-primary text-white d-inline-flex align-items-center justify-content-center rounded-circle"
                ></fa-icon>
                دورات وورش عمل
              </p>
            </div>
            <div class="col-6">
              <p class="fs-5 fw-bold">
                <fa-icon
                  [icon]="faCheck"
                  style="width: 40px; height: 40px"
                  class="me-3 fs-3 bg-primary text-white d-inline-flex align-items-center justify-content-center rounded-circle"
                ></fa-icon>
                لقاءات وندوات
              </p>
            </div>
            <div class="col-6">
              <p class="fs-5 fw-bold">
                <fa-icon
                  [icon]="faCheck"
                  style="width: 40px; height: 40px"
                  class="me-3 fs-3 bg-primary text-white d-inline-flex align-items-center justify-content-center rounded-circle"
                ></fa-icon>
                خبراء دوليون
              </p>
            </div>
            <div class="col-6">
              <p class="fs-5 fw-bold">
                <fa-icon
                  [icon]="faCheck"
                  style="width: 40px; height: 40px"
                  class="me-3 fs-3 bg-primary text-white d-inline-flex align-items-center justify-content-center rounded-circle"
                ></fa-icon>
                شهادات حضور
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <img src="./assets/images/landing/skills-side.png" class="img-fluid mt-5" alt="skills" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentEvents.length > 0" class="py-5 mb-5 orange-dots">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div>
          <h6 class="fw-bold text-primary">فعالياتنا</h6>
          <h6 class="fs-1 fw-bold text-secondary mt-4">اختر فعالية و شارك معنا</h6>
        </div>

        <div class="d-none d-lg-block align-self-center">
          <button class="swiper-button mx-2" (click)="currentEventsSwiper.next()" type="button">
            <fa-icon [icon]="faArrowRightLong"></fa-icon>
          </button>
          <button class="swiper-button mx-2" (click)="currentEventsSwiper.previous()" type="button">
            <fa-icon [icon]="faArrowLeftLong"></fa-icon>
          </button>
        </div>
      </div>

      <div class="carousel my-5 position-relative">
        <swiper-container swiper #currentEventsSwiper="swiper" [options]="swiperOptions" init="false">
          <swiper-slide *ngFor="let event of currentEvents">
            <app-card [event]="event"></app-card>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>

  <div *ngIf="pastEvents.length > 0" class="py-5 mb-5 orange-dots">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div>
          <h6 class="fs-1 fw-bold text-secondary">الفعاليات السابقة</h6>
        </div>

        <div class="d-none d-lg-block align-self-center">
          <button class="swiper-button mx-2" (click)="pastEventsSwiper.next()" type="button">
            <fa-icon [icon]="faArrowRightLong"></fa-icon>
          </button>
          <button class="swiper-button mx-2" (click)="pastEventsSwiper.previous()" type="button">
            <fa-icon [icon]="faArrowLeftLong"></fa-icon>
          </button>
        </div>
      </div>

      <div class="carousel my-5 position-relative">
        <swiper-container swiper #pastEventsSwiper="swiper" [options]="swiperOptions" init="false">
          <swiper-slide *ngFor="let event of pastEvents">
            <app-card [event]="event"></app-card>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>

  <div id="home-benefiters" class="bg-opacity-10 bg-primary pb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h6 class="fw-bold text-primary mt-5">المستفيدون</h6>
          <h6 class="fs-1 fw-bold text-secondary mt-4 mb-3">دورات لجميع من لهم علاقة بالعملية التعليمية</h6>
          <p class="lead mb-5 text-l-gray">
            يستهدف مكتب التربية العربي لدول الخليج من خلال برامجه التدريبية كل من لهم علاقة بالعملية التعليمية
          </p>

          <div class="d-flex align-items-center gap-5 text-center">
            <div class="">
              <img src="./assets/images/landing/beneficiaries-3.png" alt="قيادات التعليم" />
              <p class="fs-4 fw-bold mt-3">قيادات التعليم</p>
            </div>
            <div class="">
              <img src="./assets/images/landing/beneficiaries-4.png" alt="المعلم" />
              <p class="fs-4 fw-bold mt-3">المعلم</p>
            </div>
            <div class="">
              <img src="./assets/images/landing/beneficiaries-1.png" alt="ولي الأمر" />
              <p class="fs-4 fw-bold mt-3">ولي الأمر</p>
            </div>
            <div class="">
              <img src="./assets/images/landing/beneficiaries-2.png" alt="الطالب" />
              <p class="fs-4 fw-bold mt-3">الطالب</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <img
            src="./assets/images/landing/beneficiaries-side.png"
            class="img-fluid cover side-image"
            style="min-height: 400px; max-height: 600px"
            alt="beneficiaries"
          />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="attendees > 0 || workshopsCourses > 0 || seminars > 0" #trigger id="home-numbers" class="py-5">
    <div class="container">
      <div class="container">
        <h6 class="fw-bold text-primary mt-5">أرقام هامة</h6>
        <h6 class="fs-1 fw-bold text-secondary mt-4 mb-5">المنصة في أرقام</h6>
      </div>

      <div class="row my-5 d-flex justify-content-center">
        <div *ngIf="workshopsCourses > 0" class="col-md-3 col-6">
          <div class="card border-0 shadow h-100 rounded-3">
            <div class="card-body py-4">
              <div
                class="icon text-light bg-secondary rounded-circle d-flex align-items-center justify-content-center ms-auto"
                style="width: 60px; height: 60px"
              >
                <img src="./assets/images/landing/analytics-1.svg" alt="الدورات و ورش العمل" />
              </div>
              <span class="d-block text-center text-primary fw-bold" style="font-size: 3.3rem; white-space: nowrap">
                +
                <span counter class="counter" [stop]="workshopsCourses" [trigger]="trigger"></span>
              </span>
              <span class="d-block text-center fw-bold text-l-gray">الدورات و ورش العمل</span>
            </div>
          </div>
        </div>
        <div *ngIf="attendees > 0" class="col-md-3 col-6">
          <div class="card border-0 shadow h-100 rounded-3">
            <div class="card-body py-4">
              <div
                class="icon text-light bg-secondary rounded-circle d-flex align-items-center justify-content-center ms-auto"
                style="width: 60px; height: 60px"
              >
                <img src="./assets/images/landing/analytics-2.svg" alt="المشاركون" />
              </div>
              <span class="d-block text-center text-primary fw-bold" style="font-size: 3.3rem; white-space: nowrap">
                +
                <span counter class="counter" [stop]="attendees" [trigger]="trigger"></span>
              </span>
              <span class="d-block text-center fw-bold text-l-gray">المشاركون</span>
            </div>
          </div>
        </div>
        <div *ngIf="seminars > 0" class="col-md-3 col-6">
          <div class="card border-0 shadow h-100 rounded-3">
            <div class="card-body py-4">
              <div
                class="icon text-light bg-secondary rounded-circle d-flex align-items-center justify-content-center ms-auto"
                style="width: 60px; height: 60px"
              >
                <img src="./assets/images/landing/analytics-3.svg" alt="الندوات و اللقاءات" />
              </div>
              <span class="d-block text-center text-primary fw-bold" style="font-size: 3.3rem; white-space: nowrap">
                +
                <span counter class="counter" [stop]="seminars" [trigger]="trigger"></span>
              </span>
              <span class="d-block text-center fw-bold text-l-gray">الندوات و اللقاءات</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div auth *ifLoggedIn id="contact-us" class="bg-primary bg-opacity-10 world start end">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h6 class="fw-bold text-primary mt-5">تواصل معنا</h6>
          <h6 class="fs-1 fw-bold text-secondary mt-4 mb-5">لا تتردد في الكتابة</h6>

          <form #ngForm="ngForm" [formGroup]="form" (ngSubmit)="send()">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control py-3 bg-very-light-gray"
                    placeholder="الاسم"
                    id="full_name"
                    formControlName="full_name"
                    readonly
                    [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(full_name, ngForm)}"
                  />
                  <div *ngIf="full_name.invalid" class="invalid-feedback">{{ full_name.errors | error }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <input
                    type="email"
                    class="form-control py-3 bg-very-light-gray"
                    id="email"
                    placeholder="البريد الإلكتروني"
                    formControlName="email"
                    readonly
                    [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(email, ngForm)}"
                  />
                  <div *ngIf="email.invalid" class="invalid-feedback">{{ email.errors | error }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <app-phone-input
                    formControlName="phone"
                    [invalid]="errorStateMatcher.isErrorState(phone, ngForm)"
                    [error]="phone.errors | error"
                  ></app-phone-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control py-3"
                    id="address"
                    placeholder="العنوان"
                    formControlName="address"
                    [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(address, ngForm)}"
                  />
                  <div *ngIf="address.invalid" class="invalid-feedback">{{ address.errors | error }}</div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <textarea
                    rows="6"
                    class="form-control py-3"
                    id="message"
                    placeholder="الرسالة"
                    formControlName="message"
                    [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(message, ngForm)}"
                  ></textarea>
                  <div *ngIf="message.invalid" class="invalid-feedback">{{ message.errors | error }}</div>
                </div>
              </div>
              <div class="col-md-12 text-end">
                <button class="btn btn-primary py-3 px-5" [disabled]="sending" type="submit">إرسال</button>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-4">
          <h6 class="fw-bold text-primary mt-5">هل تحتاج الى مساعدة؟</h6>
          <h6 class="fs-1 fw-bold text-secondary mt-4 mb-5">ابق على تواصل معنا</h6>

          <div class="d-flex flex-row align-items-center mb-3">
            <div>
              <fa-icon
                [icon]="faPhone"
                flip="horizontal"
                class="bg-primary rounded-3 text-light fs-3 d-flex align-items-center justify-content-center me-3"
                style="width: 50px; height: 50px"
              ></fa-icon>
            </div>
            <div class="d-flex flex-column">
              <p class="mb-0">هل لديك استفسار اتصل بنا؟</p>
              <p class="mb-0"><a class="text-secondary" dir="ltr" href="tel:+966 114 800 555">(+966) 114 800 555</a></p>
            </div>
          </div>

          <div class="d-flex flex-row align-items-center mb-3">
            <div>
              <fa-icon
                [icon]="faEnvelope"
                flip="horizontal"
                class="bg-primary rounded-3 text-light fs-3 d-flex align-items-center justify-content-center me-3"
                style="width: 50px; height: 50px"
              ></fa-icon>
            </div>
            <div class="d-flex flex-column">
              <p class="mb-0">ارسل لنا بريد إلكتروني</p>
              <p class="mb-0">
                <a class="text-secondary" dir="ltr" href="mailto:workshops@abegs.org">workshops@abegs.org</a>
              </p>
            </div>
          </div>

          <div class="d-flex flex-row align-items-center mb-3">
            <div>
              <fa-icon
                [icon]="faLocationDot"
                flip="horizontal"
                class="bg-primary rounded-3 text-light fs-3 d-flex align-items-center justify-content-center me-3"
                style="width: 50px; height: 50px"
              ></fa-icon>
            </div>
            <div class="d-flex flex-column">
              <p class="mb-0">عنوان المراسلة</p>
              <p class="mb-0">المملكة العربية السعودية - الرياض - رمز بريدي 12622 - حي الوزارات 8184 - 3138</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <iframe
      title="عنوان المكتب على الخريطة"
      class="d-block mt-5"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.4406924822692!2d46.62238387876512!3d24.677373154954676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1c5aeb3823af%3A0xccf806fdbe26923a!2sABEGS!5e0!3m2!1sen!2seg!4v1672107424332!5m2!1sen!2seg"
      width="100%"
      height="300"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
      loading="async"
    ></iframe>
  </div>
</main>
<app-footer></app-footer>
