<footer class="footer bg-dark text-light">
  <div class="container">
    <div class="row py-5">
      <div class="col-lg-8 col-lg-6">
        <h6 class="text-primary fw-bold">مكتب التربية العربي لدول الخليج</h6>
        <hr style="width: 28ch" class="border border-primary border-1 opacity-75" />

        <ul class="footer-links list-unstyled mb-5 mt-4">
          <li *ngFor="let link of links" class="list-unstyled-item ps-2 ms-3">
            <a [href]="link.href" class="">{{ link.title }}</a>
          </li>
          <li class="list-unstyled-item ps-2 ms-3">
            <a routerLink="/privacy-policy">سياسة الخصوصية</a>
          </li>
        </ul>
      </div>
      <div class="col">
        <h6 class="text-primary fw-bold">تواصل معنا</h6>
        <hr style="width: 12ch" class="border border-primary border-1 opacity-75" />

        <div class="mt-4 text-light">
          <p>
            <fa-icon [icon]="faLocationDot" class="me-3"></fa-icon>
            المملكة العربية السعودية - الرياض - رمز بريدي 12622 - حي الوزارات 8184 - 3138
          </p>
          <p>
            <fa-icon [icon]="faPhone" flip="horizontal" class="me-3"></fa-icon>
            <a class="text-light" href="tel: 00966114800555">00966114800555</a>
          </p>
          <p>
            <fa-icon [icon]="faEnvelope" class="me-3"></fa-icon>
            <a class="text-light" href="mailto: workshops@abegs.org">workshops@abegs.org</a>
          </p>

          <div class="d-flex social-media-links mt-4">
            <a *ngFor="let link of socialMediaLinks" class="me-3 text-light" target="_blank" [href]="link.href">
              <fa-icon [icon]="link.icon"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-center small py-2">
      <p class="mb-0">جميع الحقوق محفوظة © لمكتب التربية العربي لدول الخليج {{ year }}</p>
    </div>
  </div>
</footer>

<a
  id="back-to-top"
  href="#"
  class="btn btn-primary shadow"
  [ngClass]="{show: scrollTopBtnVisible}"
  data-bs-toggle="tooltip"
  data-bs-placement="top"
  data-bs-title="العودة لبداية الصفحة"
>
  <fa-icon [icon]="faChevronUp"></fa-icon>
</a>
